<!--
 * @Author: inwen6
 * @Date: 2020-12-25 12:01:58
 * @LastEditTime: 2020-12-25 14:29:05
 * @LastEditors: your name
 * @Description: 商户信息
 * @FilePath: /heiqingting_gzh/src/views/merchants.vue
-->
<template>
  <div class="merchants_box">
    <van-tabs v-model="active" class="tabs" @change="changeobj">
      <van-tab v-for="(item, index) in stores" :key="index" :title="item.storeName">
        <div class="title_img">
          <van-image class="imgyibai" :src="obj.storeImg" fit="scale-down" />
        </div>
        <!-- 门店信息 -->
        <div class="mendian">
          <div class="title_txt">
            <div></div>
            <div>门店信息</div>
          </div>
          <div class="row">门店地址：{{ obj.address }}</div>
          <div class="row">门店联系人：{{ obj.contactName }}</div>
          <div class="row">联系人电话：{{ obj.mobile }}</div>
        </div>
        <div class="mendian" v-if="!isAdvance && displayProfit == 0">
          <div class="title_txt">
            <div class="tiao1"></div>
            <div class="tiao2">分润信息</div>
          </div>
          <div class="row" v-if="!isAdvance">分润比例(%)：{{ isSpecialMer ? '98' : obj.profitMargin }}</div>
          <div class="row" v-if="serviceFee">提现手续费：{{ serviceFee }}</div>
        </div>
        <div class="mendian" v-if="obj.price > 0">
          <div class="title_txt">
            <div class="tiao1"></div>
            <div class="tiao2">充电宝计费</div>
          </div>
          <div class="row">计费模式：{{ obj.billingWay == 1 ? '每30分钟计费' : '每60分钟计费' }}</div>
          <div class="row">单价：{{ obj.price }}元</div>
          <div class="row">收费详情：1分钟内免费,{{ obj.price }}元/{{ obj.billingWay == 1 ? '30分钟' : '60分钟' }}</div>
          <div v-show="obj && obj.devices && obj.devices.length > 0">
            <div class="row">设备信息：</div>
            <div v-for="(item, index) in obj.devices" :key="index">
              <div class="row" style="margin-left: 20px;">sn码：{{ item.deviceSn }}</div>
              <!-- <div class="row">设备类型：{{ item.deviceType }}</div>
              <div class="row">投放时间：{{ item.createTime ? item.createTime.split('T')[0] + '
                              '+item.createTime.split('T')[1].split('.')[0]:''}}</div>
              <div class="line"></div> -->
            </div>
          </div>
        </div>
        <div class="mendian" v-if="obj.C">
          <div class="title_txt">
            <div class="tiao1"></div>
            <div class="tiao2">充电线计费</div>
          </div>
          <div class="row">计费模式：{{ obj.C.priceType == 0 ? '标准计费' : '自定义计费' }}</div>
          <div class="row">收费详情：{{ getLinePrice(obj.C.customPrice) }}</div>
        </div>
        <div class="mendian" v-if="obj.K">
          <div class="title_txt">
            <div class="tiao1"></div>
            <div class="tiao2">充电桩计费</div>
          </div>
          <div class="row-fee">
            <div class="t1">收费详情：</div>
            <div class="fee1">{{ getStationPrice(obj.K) }}</div>

          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import { list } from 'ali-oss/lib/object';
import { Notify } from 'vant';
export default {
  data() {
    return {
      active: 0,
      stores: [],
      serviceFee: "",
      obj: {},
      isAdvance: false,
      isSpecialMer: false,
      displayProfit: 1,//是否屏蔽比例,0:不屏蔽，1屏蔽
      otherProfit: null,//显示其他比例，NULL显示原比例,
    };
  },
  mounted() {
    this.getDetails();


  },
  methods: {
    changeobj() {
      this.obj = this.stores[this.active]
      console.log(this.active);
    },
    getLinePrice(row) {
      var str = ''
      var keys = []
      for (var key in row) {
        keys.push(key)
      }
      for (var i = 0; i < keys.length; i++) {
        var hour = keys[i].split('our')[1]
        var value = row[keys[i]]
        str = str + value + '元' + hour + '小时'
        if (i != keys.length - 1) {
          str = str + '；'
        }
      }
      return str
    },
    getStationPrice(pricePac) {
      var str = ''
      pricePac.fl1.forEach(item => {
        var keys = Object.keys(item)
        console.log(item.keys)
        str = str + keys[0] + '~' + item[keys[0]] + ':' + '电费-' + pricePac.unitPrice_1 + ' 服务费-' + pricePac.servicePrice_1 + '\n'

      })
      pricePac.fl2.forEach(item => {
        var keys = Object.keys(item)
        console.log(item.keys)
        str = str + keys[0] + '~' + item[keys[0]] + ':' + '电费-' + pricePac.unitPrice_2 + ' 服务费-' + pricePac.servicePrice_2 + '\n'

      })
      pricePac.fl3.forEach(item => {
        var keys = Object.keys(item)
        console.log(item.keys)
        str = str + keys[0] + '~' + item[keys[0]] + ':' + '电费-' + pricePac.unitPrice_3 + ' 服务费-' + pricePac.servicePrice_3 + '\n'

      })
      pricePac.fl4.forEach(item => {
        var keys = Object.keys(item)
        console.log(item.keys)
        str = str + keys[0] + '~' + item[keys[0]] + ':' + '电费-' + pricePac.unitPrice_4 + ' 服务费-' + pricePac.servicePrice_4 + '\n'

      })


      return str

    },
    /**
     * 获取当前登录商户数据
     */
    getDetails() {
      this.$http.getDetails(null).then((res) => {
        if (res.code == 200) {
          this.isAdvance = res.merchant.isAdvance;
          var merchantId = res.merchant.merchantId + ''
          if (merchantId && this.$http.xxmerArr.indexOf(merchantId) != -1) {
            this.isSpecialMer = true
          }
          this._agentId = res.merchant.agentId + ''
          this._merchantId = res.merchant.merchantId + ''
          var showDevice = this._merchantId == '25820'?1:0
          this.requestStoreList(showDevice)
        }
      });
    },
    requestStoreList(showDevice) {
      this.$http.merchantstores({deviceStatus:showDevice}).then((res) => {
        if (res.code == 200) {
          var list = res.stores || []
          this.otherProfit = res.otherProfit
          this.displayProfit = res.displayProfit

          list.forEach(item => {
            var priceList = item.pricePacs
            if (this.otherProfit) {
              item.profitMargin = this.otherProfit
            }
            if (this._agentId && this._agentId == '314') {
              if (this._merchantId == this.$http.xxzhouMer) {
                item.profitMargin = 70
              }
            }

            if (priceList && priceList.length > 0) {
              for (var i = 0; i < priceList.length; i++) {
                var item0 = priceList[i];
                item[item0.deviceType] = item0.pricePac
                if (item0.deviceType.startsWith('C')) {
                  var pricePac = JSON.parse(item0.pricePac)
                  item[item0.deviceType] = pricePac
                }
                if (item0.deviceType.startsWith('K')) {
                  var pricePac = JSON.parse(item0.pricePac)
                  item[item0.deviceType] = pricePac
                }

              }

            }

          })
          this.stores = list;
          console.log('门店列表', list)
          this.serviceFee = res.serviceFee;
          if (this.stores.length != 0) {
            this.obj = res.stores[0];
          }

          console.log(this.stores, this.serviceFee, this.stores.length);
        } else {
          Notify({ type: "primary", message: res.msg });
        }
      });
    }


  },
};
</script>
<style>
.van-tabs__line {
  width: 32px;
  height: 4px;
  background: #1890ff;
  border-radius: 2px;
}
</style>
<style lang="scss" scoped>
.merchants_box {
  width: 100%;

  //   height: 100%;
  //   background: pink;
  .tabs {
    width: 375px;
    // height: 100%;
    // background: steelblue;
    margin: 0 auto;

    .title_img {
      width: 375px;
      height: 220px;

      .imgyibai {
        height: 100%;
        width: 100%;
      }
    }

    .mendian {
      width: 375px;
      //   background-color: teal;
      box-sizing: border-box;
      padding: 20px;

      .title_txt {
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: center;
        margin-bottom: 16px;

        div:nth-child(1) {
          width: 4px;
          height: 16px;
          background: #1890ff;
          border-radius: 2px;
        }

        div:nth-child(2) {
          margin-left: 4px;
          flex: 1;
          height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 22px;
        }
      }

      .row {
        width: 328px;
        // height: 20px;
        margin-top: 8px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }

      .row-fee {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

        line-height: 16px;

        margin-top: 8px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        width: 100%;

      }

      .row-fee .t1 {
        flex-shrink: 0;
      }

      .row-fee .fee1 {
        font-size: 12.5px;
        color: rgb(153, 153, 153);
      }

      .line {
        width: 100%;
        height: 1px;
        background-color: #f8f8f8;
        margin: 16px 0;
      }
    }
  }
}</style>
